import { intl } from 'di18n-react';
import { useEffect, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Card } from 'antd';
import classBind from 'classnames/bind';
import useUpload from '@/hooks/uploadWiki';
import { TaskManager } from '@/utils/upload';
import TextOnlyModal from '@/components/TextOnlyModal';
import UploadItem from './components/uploadItem';
import styles from './style.module.less';

const cx = classBind.bind(styles);

export default function UploadTips() {
  const { uploadFiles, closeTip, updateFile, deleteFile, isShowTips } = useUpload();
  const [taskManager] = useState(new TaskManager(updateFile));
  const [timer, setTimer] = useState(null);
  const [showList, setShowList] = useState(true);

  const modalMap = {
    upload: [
      intl.t('正在上传文件'),
      intl.t(
        '点击确认后，所有正在上传的文件都将会被取消，确认要关闭进度弹窗吗？',
      ),
    ],
    importFromCooper: [
      intl.t('正在导入Cooper文档'),
      intl.t(
        '导入暂不支持中止，点击确认仅会关闭导入弹窗，我们将在后台继续为你导入，仍要关闭导入进度弹窗吗？',
      ),
    ],
    importFromDK: [
      intl.t('正在迁入知识库页面'),
      intl.t(
        '迁入暂不支持中止，点击确认仅会关闭导入弹窗，我们将在后台继续为你移动，仍要关闭导入进度弹窗吗？',
      ),
    ],
  };

  const node = useMemo(() => {
    const divNode = window.document.createElement('div');
    divNode.style = 'position: fixed; bottom: 32px; right: 32px;z-index:1000';
    window.document.body.appendChild(divNode);
    return divNode;
  }, [uploadFiles.length === 0]);

  useEffect(() => {
    if (uploadFiles.length === 0) {
      setTimer(
        setTimeout(() => {
          closeTip();
        }, 2000),
      );
    } else {
      clearTimeout(timer);
    }

    return () => {
      if (window.document.body) { // fix:报白屏错误contains不存在
        window.document.body.contains(node)
        && window.document.body.removeChild(node);
      }
    };
  }, [uploadFiles.length === 0]);

  const Close = (
    <div
      className={cx('dk-upload-close', 'dk-upload-card-icon')}
      onClick={openConfirm}
    />
  );

  const SwitchIcon = (
    <div
      className={cx(
        showList ? 'expand' : 'minimize',
        'dk-upload-switch',
        'dk-upload-card-icon',
      )}
      onClick={() => {
        setShowList(!showList);
      }}
    />
  );

  function openConfirm() {
    if (uploadFiles.findIndex((target) => target.isFinish === false) === -1) {
      closeTip();
    } else {
      // 区分上传场景。。。
      let type = uploadFiles.length ? 'upload' : '';
      if (uploadFiles.length && uploadFiles[0].isImportFromDK) {
        type = 'importFromDK';
      } else if (uploadFiles.length && uploadFiles[0].isImport) {
        type = 'importFromCooper';
      }
      const [title, content] = modalMap[type];
      TextOnlyModal.confirm({
        title,
        content,
        onOk() {
          taskManager.pauseAll();
          closeTip(); // close的时候直接把uploadlist清理掉
        },
      });
    }
  }

  const Header = useMemo(() => {
    let uploadingNum = 0;
    uploadFiles.forEach((target) => {
      target.isFinish === false && uploadingNum++;
    });
    if (uploadingNum === 0) {
      return (
        <div className={cx('dk-upload-header')}>
          <div
            className={cx('dk-upload-done')}
            alt="ASYNC" />
          <span className={cx('dk-upload-text')}>{intl.t('全部导入完成')}</span>
        </div>
      );
    }
    return (
      <div className={cx('dk-upload-header')}>
        <div
          className={cx('dk-upload-uploading')}
          alt="ASYNC" />
        <span className={cx('dk-upload-text')}>{intl.t('正在导入')}</span>
        {/* <span>{uploadingNum}/{uploadFiles.length}</span> */}
      </div>
    );
  }, [uploadFiles]);

  if (!isShowTips) {
    return null;
  }

  return createPortal(
    <div
      className={cx('dk-upload-container', {
        'dk-upload-minimize': !showList,
      })}
    >
      <Card
        title={Header}
        style={{ width: 400 }}
        tabBarExtraContent={SwitchIcon}
        extra={
          <div>
            {Close}
            {SwitchIcon}
          </div>
        }
      >
        {showList
          && uploadFiles.map(
            ({
              fileName,
              percent,
              size,
              id,
              knowledgeId,
              callback,
              file,
              parentId,
              type,
              err,
              taskId,
              stateMsg,
              isFail,
              isFinish,
              isImport,
              isImportFromDK,
              resourceType,
              currPageId,
              modeType,
            }) => (
              <UploadItem
                key={id}
                id={id}
                type={type}
                file={file}
                currPageId={currPageId}
                parentId={parentId}
                knowledgeId={knowledgeId}
                percent={percent}
                size={size}
                fileName={fileName}
                callback={callback}
                taskManager={taskManager}
                deleteFile={deleteFile}
                taskId={taskId}
                err={err}
                stateMsg={stateMsg}
                isFail={isFail}
                isFinish={isFinish}
                isImport={isImport}
                isImportFromDK={isImportFromDK}
                resourceType={resourceType}
                modeType={modeType}
              />
            ),
          )}
      </Card>
    </div>,
    node,
  );
}
