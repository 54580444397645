const mockMain = (mockType) => {
  return `https://mock.xiaojukeji.com/mock/${mockType}`
}
const getApi = ({ path, service = '/metis', version = '/v1', mock, originService = '/cooper_gateway', mockType = 8748 }) => {
  if (mock) {
    return `${mockMain(mockType) + service + version + path}`;
  }
  return `${`${originService}${service}${version}${path}`}`;
}


export default {
  // 目录树相关
  // GET_CATALOG_LIST: getApi({ path: '/knowledge/:knowledgeId/pages' }),
  GET_CATALOG_LIST: getApi({ path: '/knowledge/:knowledgeId/list' }),

  CREATE_SUB_PAGE: getApi({ path: '/knowledge/:knowledgeId/page/create' }),
  CREATE_SUB_EXCEL: getApi({ path: '/knowledge/:knowledgeId/excel/create' }),
  DELETE_CURR_PAGE: getApi({ path: '/page/batchDelete', version: '/v2' }),
  RENAME_CURR_PAGE: getApi({ path: '/page/:pageId/rename' }),
  MOVE_CURR_PAGE: getApi({ path: '/knowledge/:knowledgeId/page/move' }),
  COPY_PAGE: getApi({ path: '/page/copy' }),

  // 查看签批进度
  GET_APPROVAL_PROGRESS: getApi({ path: '/sign/message', version: '/v1' }),
  // 页面详情页
  GET_PAGE_PERMISSION: getApi({ path: '/page/:pageId/perm' }),
  // 页面必须使用的数据
  GET_PAGE_REQUIRED: getApi({ path: '/page/:pageId/details/required' }),
  GET_DK_PERMISSION: getApi({ path: '/knowledge/:knowledgeId/perm' }),
  GET_PAGE_INHERIT_MEMBER: getApi({ path: '/page/cooperators/inherit/page', version: '/v2' }),
  GET_PAGE_INDIVIDUAL_MEMBER: getApi({ path: '/page/cooperators/individual/page', version: '/v2' }),
  SEARCH_PAGE_MEMBER: getApi({ path: '/page/cooperators/search/page', version: '/v2' }),
  ADD_PAGE_MEMBER: getApi({ path: '/page/:pageId/cooperators/batch' }),
  CHANGE_PAGE_ROLE: getApi({ path: '/page/:pageId/cooperators/role' }),
  DEL_PAGE_MEMBER: getApi({ path: '/page/:pageId/members' }),
  DEL_PAGE_GROUP: getApi({ path: '/page/:pageId/group' }),
  ADD_PAGE_GROUP: getApi({ path: '/page/:pageId/groups/batch' }),
  CHANGE_INHERIT_TYPE: getApi({ path: '/page/:pageId/cooperation/cooperationType' }),
  GET_INHERIT_TYPE: getApi({ path: '/page/:pageId/cooperation/cooperationType' }),
  GET_PREVIEW_URL: getApi({ path: '/preview' }),
  NOTICE: getApi({ path: '/page/pub/notice' }),
  SET_NOTICE_TEMP: getApi({ path: '/page/pub/notice/temp/save' }),
  GET_NOTICE_TEMP: getApi({ path: '/page/:pageId/pub/notice/temp/get' }),

  GET_SNAPSHOT_PREVIEW_URL: getApi({ path: '/preview/sign' }),

  GET_PREVIEW_FILE_INFO: getApi({ path: '/page/:pageId/info' }),
  TRANSFER_PAGE_OWNER: getApi({ path: '/translate/translatePage' }),

  // 创建知识库 不知道还要不
  CREATE_DK_NEW: getApi({ path: '/space/knowledge/create', service: '/adapter' }),
  // 创建知识库老接口
  CREATE_DK: getApi({ path: '/createMetis' }),
  GET_TEMPLATE: getApi({ path: '/template/list' }),
  TEAM_MEMBERS: getApi({ service: '/adapter', path: '/team/:teamId/members' }),
  TEAM_TRANSFER: getApi({ path: '/team/transfer', service: '/gemini', version: '/v2' }),
  TEAM_DETAIL: getApi({ service: '/adapter', path: '/team/:teamId/detail' }),
  GET_DEPARTMENT: getApi({ path: '/search/departments' }),
  MY_SPACE_CREATION: getApi({ path: '/mySpaceCreation?spaceType=TEAM_SPACE' }),
  CAN_DEPARTMENT_USE: getApi({ path: '/canDepartmentUse' }),

  // 首页
  GRT_TENDER_DATA: getApi({ path: '/knowledge/:knowledgeId/dynamic' }),

  // 新人引导
  GET_UNREAD_LIST: getApi({ path: '/notice/unread', service: '/kaleido' }),
  GET_UNREAD_ITEM: getApi({ path: '/notice/:position/unread', service: '/kaleido' }),
  SET_READ_BATCH: getApi({ path: '/notice/read', service: '/kaleido' }),
  // 搜索
  GET_SEARCH_DATA_V2: getApi({ path: '/search/all', service: '/adapter', version: '/v2' }),
  GET_SEARCH_DATA: getApi({ path: '/directory/search/all', service: '/adapter', version: '/v2' }),
  // 知识库的搜索
  GET_SEARCH_DATA_DK: getApi({ path: '/search', version: '/v1' }),
  GET_RECENT_DATA: getApi({ path: '/search/recent/visit' }),
  GET_VISIT_DK: getApi({ path: '/teams/recent', service: '/kaleido' }),
  GET_USER_DATA_DK: getApi({ path: '/member/search' }),
  GET_USER_DATA_COOPER: getApi({ path: '/search/user', version: '/v2', service: '/adapter' }),
  GET_TRANSFER_USER: getApi({ path: '/search/forTranslate' }),
  // 页面详情
  PAGE_DETAIL: getApi({ path: '/page/:pageId/details' }),
  // 页面样式
  PAGE_STYLE: getApi({ path: '/page/style/update' }),

  // 快照详情

  SNAPSHOT_DETAIL: getApi({ path: '/sign/:pageId/details?version=:version&mapId=:mapId' }),

  GET_CHANGE_OWNER_PERM: getApi({ path: '/check/transfer/apply' }),
  // didoc详情
  DIDOC_DETAIL: '/didocapi/docs/:guid',
  // didoc历史记录
  DIDOC_HISTORY: '/didocapi/histories/:guid/:timestamp',
  // 批量添加页面成员
  BATCH_ADD_PAGE_COOPERATORS: getApi({ path: '/page/cooperators/batch' }),

  // 发布版本
  RELEASE_VERSION: getApi({ path: '/page/:pageId/history/apply' }),

  // 重新发起审批
  INITIATE_APPROVAL: getApi({ path: '/page/:pageId/history/reCreate' }),

  // 恢复版本
  REVERT_VERSION: getApi({ path: '/page/:pageId/history/revert' }),
  // 查询所有版本
  VERSION_LIST: getApi({ path: '/page/:pageId/history/list' }),
  // 设置
  UPDATE_DK: getApi({ path: '/updateMetisProperties' }),
  GET_DK_DETAIL: getApi({ path: '/metisDetail?metisSpaceId=:knowledgeId' }),
  UMBOUND_SPACE: getApi({ path: '/unboundByMetis' }),
  GET_DK_MEMBERS: getApi({ path: '/knowledge/:knowledgeId/members/page' }), // 查询知识库的成员列表
  GET_DK_GROUP_MEMBERS: getApi({ path: '/knowledge/:knowledgeId/members/groupPage' }),
  GET_DK_SINGLE_MEMBERS: getApi({ path: '/knowledge/:knowledgeId/members/memberPage' }),
  SEARCH_MEMBERS: getApi({ path: '/search/addMember' }), // 添加成员时搜索【知识库成员和页面成员】
  SEARCH_CUSTOM_MEMBERS: getApi({ path: '/custom/group/search/addMember' }),
  ADD_DEL_MEMBERS: getApi({ path: '/knowledge/:knowledgeId/members' }), // 添加成员【知识库成员和页面成员】
  CHANGE_MEMBERS_ROLE: getApi({ path: '/knowledge/:knowledgeId/members/role' }),
  BE_DEPARTMENT_OR_NOT: getApi({ path: '/beDepartmentOrNot' }),
  BATCH_ADD_MEMBERS: getApi({ path: '/knowledge/members/batch' }),
  ADD_GROUP: getApi({ path: '/custom/group/:knowledgeId/add' }),
  UPDATE_GROUP: getApi({ path: '/custom/group/:knowledgeId/update' }),
  SEARCH_GROUP: getApi({ path: '/search/:knowledgeId/member/group' }),
  DELETE_GROUP: getApi({ path: '/knowledge/:knowledgeId/group' }),
  GET_EXTERNAL_GROUP_MEMBER: getApi({ path: '/member/:groupId/:type' }), // 获取选人组件下的组成员
  GET_GROUP_MEMBER: getApi({ path: '/custom/group/:knowledgeId/queryMember' }), // 获取自定义成员组的成员
  ADD_DEPARTMENT_GROUP: getApi({ path: '/knowledge/:knowledgeId/membersGroup' }),
  TRANSFER_DK_OWNER: getApi({ path: '/translate/translateMetisV2' }),
  SWITCH_GRUOP: getApi({ path: '/knowledge/switch/setting' }),
  SWITCH_SHARING: getApi({ path: '/knowledge/:knowledgeId/sharing' }),
  GET_PAGE_INDIVIDUAL_MEMBER_ALONEPERM: getApi({ path: '/page/member/operations', version: '/v2' }), // 权限:-当前页面单独授权

  GET_PAFE_DETAIL_PAGE_VIEW: getApi({ path: '/stat/knowledge/page/:pageId/data' }),
  GET_TEAMS_ADMIN: getApi({ service: '/adapter', path: '/teams/admin' }),
  TRANS_KNOWLEDGE: getApi({ service: '/adapter', path: '/personal/space/knowledge/trans' }),

  // 申请
  APPLY_DK: getApi({ path: '/space/:spaceId/apply/owner', service: '/gemini' }),
  APPLY_VERIFY: getApi({ path: '/space/:spaceId/apply/owner/check', service: '/gemini' }),

  APPLY_PAGE: getApi({ path: '/transfer/resource/{resourceId}/apply/owner', service: '/cooperation' }),
  APPLY_PAGE_VERIFY: getApi({ path: '/transfer/resource/:resourceId/apply/owner/check', service: '/cooperation' }),
  // 回收站
  GET_RECYCLEBIN_LIST: getApi({ path: '/knowledge/:knowledgeId/trash/list' }),
  RECYCLEBIN_RESTORE: getApi({ path: '/knowledge/:knowledgeId/trash/restore/resource/:resourceId' }),
  RECYCLEBIN_DELETE: getApi({ path: '/knowledge/:knowledgeId/trash/remove/resource/:resourceId' }),

  // 登录
  LOGIN: '/cooper_gateway/v1/sso/login/user',
  // 登出
  LOGOUT: '/cooper_gateway/v1/sso/login/logout',
  DK_OWNER_INFO: getApi({ path: '/metisOwnerInfo?metisSpaceId=:metisSpaceId' }),
  PAGE_OWNER_INFO: getApi({ path: '/page/:pageId/owner' }),

  // 知识库列表
  // DK_LIST: getApi({ path: '/knowledge/list' }),
  DK_LIST: getApi({ path: '/teams', service: '/kaleido' }),
  DK_LIST_OWN: getApi({ path: '/tab/teams', service: '/kaleido' }),
  ON_DEPARTMENT_PROCESS_LIST: getApi({ path: '/metisApplyDetail' }),
  UNBOUND_TEAM_DK: getApi({ path: '/unboundBySpace?spaceId=:spaceId&metisSpaceId=:metisSpaceId' }),
  BOUND_TEAM_DK: '/cooper_gateway/metis/v1/bindBySpace',
  MY_KNOWLEDGE_COUNT_MEMBER: getApi({ path: '/knowledge/list/count' }),

  CREACT_MH: getApi({ path: '/portal/create', version: '/v2' }), // 创建门户
  CHECK_MH_NAME: getApi({ path: '/portal/check/name', version: '/v2' }), // 校验门户名称是否唯一
  CHECK_MH_SYMBOL: getApi({ path: '/portal/check/symbol', version: '/v2' }), // 校验门户域名是否唯一
  TEAM_DK: '/cooper_gateway/metis/v1/metisOfSpaceAndTeam?spaceId=:teamId',
  // 上传封面
  UPLOAD_COVER: '/didocapi/upload/cover',

  // 获取apollo配置
  GET_APOLLO_CONFIG: '//as.xiaojukeji.com/ep/as/conf',

  // 分享相关
  PAGEID_FROM_SHAREID: getApi({ path: '/share/:shareId/swap' }),
  SHARE_GET_CATALOG_LIST: getApi({ path: '/share/:shareId/knowledge/pages' }),
  SHARE_PAGE_DETAIL: getApi({ path: '/share/:shareId/page/detail' }),
  SHARE_PAGE_DETAIL_BOOK: getApi({ path: '/share/:shareId/knowledge/page/detail' }),
  SHARE_DK_DETAIL: getApi({ path: '/share/:shareId/knowledge/detail' }),
  SHARE_CONTROL: getApi({ path: '/share/control' }),
  SHARE_MODAL_SETTING: getApi({ path: '/share/:resourceId/type/:resourceType/query' }),
  GET_SHARE_PREVIEW_URL: getApi({ path: '/preview/share/:shareId/page' }),
  GET_SHARE_PREVIEW_FILE_INFO: getApi({ path: '/page/share/:shareId/page/:pageId/info' }),
  GET_BOOK_SHARE_PREVIEW_URL: getApi({ path: '/preview/share/:shareId/knowledge' }),
  GET_BOOK_SHARE_PREVIEW_FILE_INFO: getApi({ path: '/knowledge/share/:shareId/page/:pageId/info' }),
  GET_SUB_SHARE_CATALOGTREE_DATA: getApi({ path: '/share/:shareId/page/pages' }),

  // 上传相关
  UPLOAD_SLICE: getApi({ path: '/knowledge/:knowledgeId/uploadSliceFile' }),
  UPLOAD_SINGLE: getApi({ path: '/knowledge/:knowledgeId/uploadFile' }),
  UPLOAD_SPLIT_DONE: getApi({ path: '/open/resource/completeBatchResourceNormal', version: '/v3', service: '/adapter' }),
  // 下载
  DOWNLOAD: getApi({ path: '/knowledge/:knowledgeId/download' }),
  DOWNLOADWORDPDF: getApi({ path: '/page/:pageId/download/:type' }),

  // 标签相关
  TAG_GET_LIST: getApi({ path: '/knowledge/:knowledgeId/tags' }),
  TAG_GET_CHOOSE_PAGE_LIST: getApi({ path: '/knowledge/:knowledgeId/tag/:tagId/pages' }),
  TAG_DELETE: getApi({ path: '/delete/page/tag' }),
  TAG_ADD: getApi({ path: '/add/page/tag' }),
  PAGE_TAG_LIST: getApi({ path: '/page/:pageId/manage/tags' }),
  TAG_ADD_SEARCH: getApi({ path: '/page/:pageId/add/tags/search' }),
  TAG_SEARCH: getApi({ path: '/tags/search' }),
  // 统计相关
  GET_CREATED_PAGE: getApi({ path: '/import/guide/:knowledgeId' }),
  // 模板相关
  // 模板详情
  TEMPLATE_DETAIL: getApi({ path: '/knowledge/:knowledgeId/template/:templateId/detail' }),
  // 知识库模板列表
  KNOWLEDGE_TEMPLATE_LIST: getApi({ path: '/knowledge/:knowledgeId/template/page' }),
  // 最近使用的模板
  RECENT_TEMPLATE_LIST: getApi({ path: '/knowledge/:knowledgeId/template/used/page' }),
  // 统计最近使用的模板
  COUNT_RECENT_TEMPLATE: getApi({ path: '/knowledge/:knowledgeId/templateUse/:templateId' }),
  // 默认模板列表
  DEFAULT_TEMPLATE_LIST: getApi({ path: '/knowledge/:knowledgeId/template/default/page' }),
  // 添加&更新模板
  SAVE_TEMPLATE: getApi({ path: '/knowledge/:knowledgeId/template' }),
  // 删除模板
  DELETE_TEMPLATE: getApi({ path: '/knowledge/:knowledgeId/template/:templateId' }),
  // 重命名编辑器文档标题
  RENAME_DOC: '/didocapi/docs/title/update/:docId',
  // 文件树
  GET_SPACE_LIST: '/cooper_gateway/kaleido/v1/teams/importable',
  GET_FILE_LIST: '/cooper_gateway/adapter/v1/team/:teamId/importable',
  IMPORT_FROM_COOPER: getApi({ path: '/knowledge/:knowledgeId/import/cooper' }),
  GET_FILE_TREE: '/cooper_gateway/adapter/v1/space/:spaceId',
  SEARCH_DIR_AND_FILE: '/cooper_gateway/adapter/v2/search/importable',
  // 最近
  RECENT_VISIT: getApi({ service: '/kaleido', path: '/knowledge/recent/visit' }),
  RECENT_EDIT: getApi({ service: '/kaleido', path: '/knowledge/recent/edit' }),
  ADD_RECENT_VISIT: getApi({ path: '/knowledge/recent/visit' }),
  ADD_RECENT_EDIT: getApi({ path: '/knowledge/recent/edit' }),
  GET_SUITE_LIST: getApi({ path: '/querySuiteSourceUrl', originService: '/gateway', service: '/teamcenter' }),

  // 申请权限相关
  APPLY_JOIN_DK: getApi({ path: '/knowledge/:knowledgeId/join', service: '/gemini' }),
  APPLY_URGE_DK: getApi({ path: '/knowledge/:knowledgeId/join/urge', service: '/gemini' }),
  GET_DK_APPLY_STATUS: getApi({ path: '/knowledge/:knowledgeId/join/detail', service: '/gemini' }),

  APPLY_JOIN_PAGE: getApi({ path: '/page/:pageId/join', service: '/cooperation' }),
  APPLY_URGE_PAGE: getApi({ path: '/page/:pageId/join/urge', service: '/cooperation' }),
  GET_PAGE_APPLY_STATUS: getApi({ path: '/page/:pageId/join/detail', service: '/cooperation' }),

  // 页面迁移搜索
  SEARCH_KNOWLEDGE_PAGES: getApi({ path: '/searchForMove' }),
  KNOWLEDGE_MOVE_LIST: getApi({ path: '/knowledge/:knowledgeId/move/list' }),
  // 迁入知识库页面
  MOVE_KNOWLEDGE_PAGES: getApi({ path: '/knowledge/page/relocation' }),
  // 移动页面
  REMOVE_KNOW_PAGE: getApi({ path: '/knowledge/:knowledgeId/page/moveForTranslate' }),
  // 搜索能被通知的人
  SEARCH_NOTICE_MEMBER: getApi({ path: '/search/page/notice/member' }),
  // 知识门户
  MH_LIST: getApi({ path: '/portal/list' }),
  MHU_COUNT_MEMBER: getApi({ path: '/portal/list/count' }),

  // 收藏 or 取消收藏
  STAR_ACTION: getApi({ path: '/star', version: '/v2', service: '/kaleido' }),

  // wiki html页面转Didoc
  CONVERT_WIKI_TO_DIDOC: getApi({ path: '/page/mode/update', version: '/v2' }),

  // 全文评论
  GET_COMMENT_LIST: getApi({ path: '/comment' }),
  EDIT_COMMENT: getApi({ path: '/comment' }),
  DELETE_COMMENT: getApi({ path: '/comment/delete/:commentId' }),
  // 分享的全文评论
  SHARE_COMMENT: getApi({ path: '/share/comment' }),
  SHARE_DELETE_COMMENT: getApi({ path: '/share/comment/delete' }),
  APPLY_MEMBER_PERM: getApi({ path: '/resource/:resourceId/apply', version: '/v1', service: '/cooperation' }),

  // 帮助中心-网络监测服务
  HELP_NETWORK: '/cooper_gateway/v1/app/netinfo',

  // 知识库转发到社区 https://cooper.didichuxing.com/knowledge/share/page/wxnVLEvEvmmA
  // 按人查询自己管辖的公众号
  GET_OFFICIAL_ACCOUNT: getApi({ path: '/cc/cooper/getOfficialAccount' }),
  // 按人查询圈子
  GET_COMMUNITY_SPACE: getApi({ path: '/cc/cooper/getSpaceList' }),
  // 保存草稿
  SAVE_TALK_DRAFT: getApi({ path: '/cc/cooper/saveTalkDraft' }),

};


