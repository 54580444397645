import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import WhiteScreenError, { withWhiteScreenError } from '@didi/whitescreen-error';
import { getUserNameFromCookie, openNewWindow } from '@/utils';
import { useState, useEffect, useMemo } from 'react';
import { ErrorBoundaryIcon } from '@/assets/icon/index';
import { network } from './util';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const contentText = {
  useVpn: intl.t('你目前正在连接公司VPN，请检查VPN连接情况。若连接正常仍无法访问，建议断开VPN，Cooper支持在公司可信设备下进行访问；'),
  dataError: intl.t('当前网络存在异常，可尝试切换至优质网络后重新刷新页面；'),
  normal: '',
};


// 上报网络：做一次网络检测并上报，查看当时网络问题 --- @didi/whitescreen-error未来将该功能内置
// 怎么知道用户刷新页面解决了问题？ --- 点击刷新页面时埋点
// 总的问题数【omega】 - 刷新页面点击次数【omega】 - 反馈群反馈的问题数/上报后解决的问题数【naga】 约等于 网络相关的问题数+缓存【慢/代理】

// TODO：做一些事情帮助定位问题。
// 为什么会出现这个错误？ 根据埋点看到用户怎么解决的，大概可以定位
// 怎么减少这种错误产生？

const ErrorBoundary = ({ children }) => {
  const [networkType, setNetworkType] = useState('');
  const [errorMsg, setErrorMsg] = useState('');


  const feedback = useMemo(() => {
    return <a
      className={cx('link')}
      target='_blank'
      href='https://im.xiaojukeji.com/channel?uid=179964&token=3bdb88d2caad6b9ef23b726c9c11a837&id=146847933529642240'
      onClick={() => {
        window.__OmegaEvent('ep_whitescreen_open_feedback_ck', '出现白屏错误后点击加入反馈群', {
          errorsmg: errorMsg.message,
        });
      }}
  >
      Cooper 反馈群【Feedback】
    </a>
  }, [errorMsg]);

  const freshBtn = useMemo(() => {
    return <button
      className={cx('fresh-btn')}
      onClick={() => {
        window.__OmegaEvent('ep_whitescreen_refresh_ck', '出现白屏错误后点击刷新页面', {
          errorsmg: errorMsg.message,
        })
        window.location.reload(true);
      }}
>
      {intl.t('刷新页面')}
    </button>;
  }, [errorMsg])

  const fallbackFunc = () => {
    const isChunkLoadError = errorMsg.message
      && (
        errorMsg.message.indexOf('ChunkLoadError') >= 0
        || errorMsg.message.indexOf('Loading CSS chunk') >= 0
        || errorMsg.message.indexOf('Loading chunk') >= 0
      );

    return (
      <div className={cx('white-screen-container')}>
        <div className={cx('content')}>
          <img
            className={cx('img')}
            src={ErrorBoundaryIcon} />
          <div className={cx('load-error-wrap')}>
            <div
              style={{
                fontWeight: 'bold',
                paddingLeft: '24px',
              }}>{intl.t('当前模块{slot}，请参考以下解决方案：', {
                slot: isChunkLoadError ? intl.t('加载失败') : intl.t('出现问题'),
              })}</div>
            <ol>
              {
                isChunkLoadError
                  ? (
                    <>
                      <li className={cx('solution-li')}>{intl.t('可能是网络抖动，请点击')}{freshBtn}；</li>
                      <li className={cx('solution-li')}>{
                        (networkType.length > 0 && networkType !== 'normal')
                          ? contentText[networkType]
                          : <>{intl.t('如果开启了代理，请关闭代理后重新刷新页面；')}</>}</li>
                      <li className={cx('solution-li')}>{intl.t('浏览器存在缓存，请完全清空浏览器缓存后刷新页面或使用无痕模式访问页面；')}</li>
                    </>
                  )
                  : (
                    (networkType.length > 0 && networkType !== 'normal')
                      ? <li className={cx('solution-li')}>{contentText[networkType]}</li>
                      : <li className={cx('solution-li')}>{intl.t('可能是网络抖动，请点击')}{freshBtn}；</li>
                  )
                }
              <li className={cx('solution-li')}>{intl.t('如果以上尝试后仍未解决，请联系')}{feedback}{intl.t('，我们将为您提供技术支持；')}</li>
            </ol>
          </div>
        </div>
      </div>
    );
  };


  // 白屏组件配置信息
  const whiteErrorConfig = {
    env: process.env.APP_ENV, // 默认prod,线上环境请使用'prod',便于数据统计
    ldap: getUserNameFromCookie(), // 用户ldap
    fallback: fallbackFunc, // 降级UI
    omegaInstance: window.Omega, // omega实例,若未传入会取window.Omega
    dcWebhook: [
      'https://im-dichat.xiaojukeji.com/api/hooks/incoming/700c668f-fd6b-479a-a724-e98260232c8e',
    ], // 群机器人hook 可不传，并且在Naga平台上动态配置报警群，详情见下
    onError: async (error, componentStack) => {
      const { resultType, clientIp } = await network();
      setNetworkType(resultType)

      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      const networkInfo = {
        downlink: connection?.downlink || 'unknown', // 下载速度，单位 Mbps
        rtt: connection?.rtt || 'unknown', // 网络往返时间，单位 ms
        saveData: connection?.saveData || false, // 用户是否启用了数据节约模式
      };

      setErrorMsg(error)
      window.__OmegaEvent('ep_whitescreen_naga_en', '出现白屏错误', {
        errorsmg: error.message,
        networkinfo: networkInfo,
        networktypeapi: resultType,
        clientip: clientIp,
      })
    },
    isReport: process.env.APP_ENV === 'prod',
    // }, // 发生错误时事件
    onReset: () => {
      window.location.reload();
    }, // 重置组件时事件
  }


  return (
    <WhiteScreenError {...whiteErrorConfig}>
      {children}
    </WhiteScreenError>
  );
}

export default ErrorBoundary;
// // or
// const WhiteScreenErrorExample = withWhiteScreenError(ExampleComponent, whiteErrorConfig)

