import { once } from 'lodash-es';
import { getUserNameFromCookie, loadScript } from '@/utils';
import { initSign } from '@didi/wsgsig';
import raven from '@didi/raven';
import ravenPageViewPlugin from '@didi/raven/dist/plugins/pageview';
import ravenErrorPlugin from '@didi/raven/dist/plugins/error';
import ravenPerformancePlugin from '@didi/raven/dist/plugins/performance';
import ravenBlankObserverPlugin from '@didi/raven/dist/plugins/blankObserver';

function bindOmegaEvent() {
  const trackEvent = (eventId, eventLabel = '', attrs = {}, useRaven = false) => {
    if (window.Omega.trackEvent) {
      window.Omega.trackEvent(eventId, eventLabel, attrs);
    }
    if (raven && useRaven) {
      raven.trackEvent(eventId, attrs);
    }
  };
  window.__OmegaEvent = window.__OmegaEvent || trackEvent; // 自定义埋点
}

// 要在其他sdk之前
initSign({
  bizId: '73a4fb7df05932870128ab5b5a77037d',
  appVer: 'V1.0.1', // 业务方自定义，方便问题排查时定位版本tag
  os: '4', // H5、PC
});

export function initSdk() {
  // pv上报原则：第一次进来上报一次，pathname改变上报一次【layout】
  setTimeout(() => {
    if (window.Omega && window.Omega.sendPageView) {
      window.Omega.sendPageView();
    }
  }, 1000);

  // 水印
  loadScript('//sec-aegisfe.didistatic.com/static/aegisfe/water-mark1.0.js?v=001').then(() => {
    try {
      window.waterMark({
        systemId: process.env.NODE_ENV === 'production' ? 2176 : 1045,
        userId: getUserNameFromCookie(),
        textStyle: 'rgba(0,0,0,0.014)',
      });
    } catch (error) {
      console.log(error);
    }
  }).catch(() => { console.log('Failed to load water-mark') });

  // Raven
  raven.setConfig({
    appId: process.env.APP_ENV === 'prod' ? '1520' : '1545', // 在天眼平台中创建的应用分配的 appId
    uid: getUserNameFromCookie(), // 设置用户 uid 用于用户身份信息识别
  });

  raven
    .use(ravenErrorPlugin)
    .use(ravenPageViewPlugin)
    .use(ravenPerformancePlugin)
    .use(ravenBlankObserverPlugin, {
      domElem: 'root',
      waitingTime: 6000,
    })

  bindOmegaEvent();
}


export default once(() => {
  initSdk();
});
