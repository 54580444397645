import { getCookie, inPhone, isDC, setCookie } from '@/utils';
import { intl, setLocale } from 'di18n-react';
import enUS from './language/en-US.json';
import zhCN from './language/zh-CN.json';

function getMobileLang() {
  return new Promise((resolve) => {
    window.dcH5Sdk?.app?.getLang({
      onSuccess: ({ lang }) => resolve(lang),
      onFail: () => resolve('NONE'),
    });
  });
}

const initLocal = async () => {
  let locale = intl.determineLocale({
    cookieLocaleKey: 'local_language',
  });

  locale = ['zh-CN', 'en-US'].includes(locale) ? locale : 'zh-CN';

  Object.assign(intl.options, {
    locales: { 'en-US': enUS, 'zh-CN': zhCN },
    currentLocale: locale,
  });


  const ua = navigator.userAgent.toLowerCase();
  if (isDC() || (inPhone() && ua.indexOf('zhushou') !== -1)) {
    try {
      const dcLocale = await getMobileLang();
      if (['zh-CN', 'en-US'].includes(dcLocale)) {
        locale = dcLocale;
      }
    } catch (error) { console.log(error); } finally {
      setCookie('local_language', locale);
    }
  } else if (inPhone()) {
    setCookie('local_language', locale);
  }

  try {
    setLocale(locale, { cookieLocaleKey: 'local_language' });
  } catch (error) {
    console.log(error);
  }
}

initLocal();
