import { SHARE_TYPE } from '@/constants/index';
import { DocType } from '@/constants/page';
import { FILELIMITSIZE, WORDLIMITSIZE } from '@/constants/uploadWiki';
import { getApolloConfig } from '@/service/knowledge/global';
import { openLocalFileSelector } from '@/utils/upload';
import { message } from 'antd';
import { intl } from 'di18n-react';
import html2canvas from 'html2canvas';
import { Fragment } from 'react';

export function formateSource(pathname) {
  if (pathname === '/') {
    return 'home';
  }
  if (pathname.includes('/files/') || pathname.includes('/disk')) {
    return 'myspace';
  }
  if (pathname === '/team-folder') {
    return 'teamhome';
  }
  if (pathname.includes('/team-file/')) {
    return 'teamspcae';
  }
  if (pathname.includes('/tome') || pathname.includes('/fromme')) {
    return 'share';
  }
  if (pathname.includes('favorite')) {
    return 'favorites';
  }
  if (pathname === 'trash') {
    return 'trash';
  }
  return pathname;
}

export function encodeURIParamFn(par) {
  const params = {
    ...par,
  }
  if (par && par.key) {
    params.key = encodeURIComponent(par.key);
  }
  if (par && par.keyword) {
    params.keyword = encodeURIComponent(par.keyword);
  }
  if (par && par.keywords) {
    params.keywords = encodeURIComponent(par.keywords);
  }
  let paramString = '';
  if (JSON.stringify(params) !== '{}') {
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        paramString += `&${key}=${value}`;
      }
    })
  }
  return paramString.replace('&', '?');
}

export function getUserNameFromCookie() {
  const m = document.cookie.match(/username=([^;]*)/);
  return decodeURIComponent(m ? m[1] : '');
}

export function getUserUidFromCookie() {
  const m = document.cookie.match(/user_orgMemberId=([^;]*)/);
  return decodeURIComponent(m ? m[1] : '');
}

export function getTenantIdFromCookie() {
  const m = document.cookie.match(/X-Tenant-Id=([^;]*)/);
  return decodeURIComponent(m ? m[1] : '');
}

// 只有桌面端打开的webview才会挂载这个变量，在移动端不适用
export const isDesktopDC = Boolean(window.dcJSSDK);

export function isMobileDC() {
  const ua = window.navigator.userAgent;
  return ua.indexOf('iPad') !== -1;
}

export function getSystemType() {
  const agent = navigator.userAgent.toLowerCase();
  if (/macintosh|mac os x/i.test(navigator.userAgent)) return 'mac';
  if (
    agent.includes('win32')
    || agent.includes('wow32')
    || agent.includes('win64')
    || agent.includes('wow64')
  ) { return 'win'; }
  return 'mac';
}

export function deepCopy(obj) {
  // XXX: should be better
  return JSON.parse(JSON.stringify(obj));
}

// 使desc尽可能保持完整一行 & 第一个高亮关键字展示出来
export function formateDesc(desc, groupWidth, keyLength) {
  // 一个字大概12px

  const viewMaxNum = groupWidth / 12;
  // 如果两个§§之间的内容大于可显示的内容

  const highlightDesc = desc.match(/§§(\S*)§§/)?.[1] ?? '';
  if (highlightDesc.length > viewMaxNum) {
    return `§§${highlightDesc}§§`;
  }

  const wordInFront = desc.indexOf('§') + keyLength;

  if (wordInFront > viewMaxNum) {
    const start = Math.min(desc.indexOf('§'), wordInFront - viewMaxNum + 5);
    return desc.slice(start, desc.length);
  }

  return desc;
}

export const loadScript = async (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.rel = 'prefetch';
    script.defer = 'defer';

    script.crossOrigin = 'Anonymous';

    script.onload = function onload() {
      if (!this.readyState || this.readyState === 'complete') {
        resolve();
      }
    };

    script.onreadystatechange = script.onload;
    document.body.appendChild(script);
  });
};

export const formatDateTime = (timestamp, fmt = 'yyyy-MM-dd hh:mm') => {
  if (!timestamp) {
    return null;
  }
  const date = new Date(timestamp);
  let result = fmt;
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    result = result.replace(
      RegExp.$1,
      `${date.getFullYear()}`.substr(4 - RegExp.$1.length),
    );
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      result = result.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length),
      );
    }
  }
  return result;
};

// 转换时间函数用这个
export function blurTime(time) {
  const now = new Date();
  const NowYear = now.getFullYear();
  const NowMonth = now.getMonth();
  const NowDate = now.getDate();
  const compare = new Date(time);
  const CompareYear = compare.getFullYear();
  const CompareMonth = compare.getMonth();
  const CompareDate = compare.getDate();

  // 如果年月日相同，显示时分
  if (
    NowYear === CompareYear
    && NowMonth === CompareMonth
    && NowDate === CompareDate
  ) {
    return intl.t('今天 {slot0}', { slot0: formatDateTime(time, 'hh:mm') });
  }

  // 如果年相同，显示月日时分
  if (NowYear === CompareYear) {
    return formatDateTime(time, intl.t('M月d日 hh:mm'));
  }

  // 年不同，显示年月日
  return formatDateTime(time, intl.t('yyyy年M月d日'));
}

export const computeTimeInterval = (dateTimeStamp) => {
  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const now = new Date().getTime();

  const diffValue = now - dateTimeStamp;
  if (diffValue < 0) {
    return '';
  }
  const now1 = new Date();
  const nowYear = now1.getFullYear();
  const nowMonth = now1.getMonth();
  const nowday = now1.getDate();
  const current = new Date(dateTimeStamp);
  const toYear = current.getFullYear();
  const toMonth = current.getMonth();
  const today = current.getDate();
  const hourC = diffValue / hour;
  const minC = diffValue / minute;
  if (minC < 1) {
    return intl.t('刚刚');
  }
  if (hourC < 1) {
    return intl.t('{slot0}分钟前', { slot0: parseInt(minC, 10) });
  }
  if (nowYear === toYear && nowMonth === toMonth && nowday === today) {
    return intl.t('今天 {slot0}', {
      slot0: formatDateTime(dateTimeStamp, 'hh:mm'),
    });
  }
  if (nowYear === toYear && nowMonth === toMonth && nowday - today === 1) {
    return intl.t('昨天 {slot0}', {
      slot0: formatDateTime(dateTimeStamp, 'hh:mm'),
    });
  }
  if (nowYear === toYear) {
    return formatDateTime(dateTimeStamp, intl.t('M月d日 hh:mm'));
  }
  return formatDateTime(dateTimeStamp, intl.t('yyyy年M月d日 hh:mm'));
};

export const unionArray = (arr1, arr2) => {
  return arr1.concat(
    arr2.filter((v) => {
      return !(arr1.indexOf(v) > -1);
    }),
  );
};

// 回收站时间转换
export const formatTime = (timestamp) => {
  if (timestamp < 1) {
    return intl.t('小于1小时');
  }
  if (timestamp < 24) {
    return intl.t('{slot0}小时', { slot0: Math.floor(timestamp) });
  }

  return intl.t('{slot0}天', { slot0: Math.floor(timestamp / 24) });
};

// 最近列表时间转换
export const formatRecentTime = (oldTime) => {
  if (!oldTime) {
    return '--';
  }
  const now = new Date();
  const old = new Date(oldTime);
  const nowYear = now.getFullYear();
  const toYear = old.getFullYear();

  if (nowYear === toYear) {
    return computeTimeInterval(oldTime);
  }
  return formatDateTime(oldTime, intl.t('yyyy年M月d日'));
};

// 调用接口失败后重试
function sleep(delay) {
  return new Promise((resolve, reject) => setTimeout(resolve, delay));
}

export async function retry(asyncRequest, times, delay) {
  try {
    return await asyncRequest();
  } catch (err) {
    if (--times) {
      await sleep(delay);
      return async () => {
        await retry(asyncRequest, times, delay);
      };
    }
    throw err;
  }
}

export const getUrlParams = (name) => {
  let reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(decodeURI(r[2]));
  return null;
};

export const setCookie = (name, value, seconds = 0) => {
  let expires = '';

  if (seconds !== 0) {
    // 设置cookie生存时间
    let date = new Date();
    date.setTime(date.getTime() + seconds * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }

  document.cookie = `${name}=${encodeURI(value)}${expires}; path=/`; // 转码并赋值
};

export const getCookie = (name) => {
  let arr = document.cookie.split(';');
  let obj = {};
  arr.map((v) => {
    let [key, value] = v.split('=');
    key = key.trim();
    obj[key] = value;
    return null;
  });
  if (obj[name]) {
    return obj[name];
  }
  return undefined;
};

export const parseUrlKnowledgeId = (pathname) => {
  let query = pathname.split('/');
  query.splice(0, 1);
  // eg: ['home'] ||  [':knowledgeId','home']
  // if (!query[1] || (query[1] && Number.isNaN(parseFloat(string62to10(query[0]))))) {
  //   return '';
  // }
  if (!query[1] || (query[1] && Number.isNaN(parseFloat(query[0])))) {
    return '';
  }
  return query[0];
};

export const getPageIdFromUrl = (pathname) => {
  let query = pathname.split('/');
  query.splice(0, 1);

  if (!query[1] || (query[1] && Number.isNaN(parseFloat(query[1])))) {
    return '0';
  }

  return query[1];
};

export const parseDKPathParams = (routes, pathname) => {
  // 根据pathname获取原始Route
  const currentRoute = routes.find((route) => {
    const path = route.path
      .replace(':knowledgeId', '\\d+')
      .replace(':shareId', '\\d+')
      .replace(':pageId', '\\d+')
      .replace(':templateId', '\\d+');
    const regex = new RegExp(`^${path}$`);
    return regex.test(pathname);
  });

  // 将原始Route中的参数一一对应具体的内容
  if (currentRoute) {
    const pathSegments = pathname.split('/');
    const paramKeys = [];
    const params = {};

    currentRoute.path.split('/').map((segment, index) => {
      if (segment.startsWith(':')) {
        paramKeys.push({
          value: segment.slice(1),
          index,
        });
      }
    });

    for (let i = 0; i < paramKeys.length; i++) {
      const { index } = paramKeys[i];
      params[paramKeys[i].value] = pathSegments[index];
    }
    return params;
  }
  return {};
};

// 图片预加载
export const loadImage = (url) => {
  let img = new Image();
  img.onload = () => {
    img.onload = null;
  };
  img.src = url;
};

export function string10to62(number) {
  let chars = '0123456789abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ'.split('');
  let radix = chars.length;
  let qutient = +number;
  let arr = [];
  let mod;
  do {
    mod = qutient % radix;
    qutient = (qutient - mod) / radix;
    arr.unshift(chars[mod]);
  } while (qutient);
  return arr.join('');
}

export function string62to10(numberCode) {
  let chars = '0123456789abcdefghigklmnopqrstuvwxyzABCDEFGHIGKLMNOPQRSTUVWXYZ';
  let radix = chars.length;
  let len = numberCode.length;
  let i = 0;
  let originNumber = 0;
  while (i < len) {
    // eslint-disable-next-line no-restricted-properties
    originNumber
      += Math.pow(radix, i++) * chars.indexOf(numberCode.charAt(len - i) || 0);
  }
  return originNumber;
}

// 生成快照
export const screenshot = async (dom, options = {}) => {
  console.time('*** screenshot');
  const canvas = await html2canvas(dom, {
    allowTaint: true,
    useCORS: true,
    ...options,
  });
  console.timeEnd('*** screenshot');
  console.time('*** toBlob');
  return new Promise((resolve) => {
    try {
      canvas.toBlob((blob) => {
        console.timeEnd('*** toBlob');
        const data = blob || new Blob();
        resolve(data);
      });
    } catch (e) {
      resolve(new Blob());
    }
  });
};

// 计算摘要
export const getEditorSummary = (view) => {
  const { content } = view.template.export();
  const EditorContent = document.createElement('div');
  EditorContent.innerHTML = content;
  const { children } = EditorContent;
  const copy = EditorContent.cloneNode();
  const max = 200;
  let num = 0;
  for (let ele of children) {
    let eleLength = ele.textContent.length;
    num += eleLength;
    copy.appendChild(ele.cloneNode(true));
    if (num > max) {
      break;
    }
  }
  return copy.innerHTML;
};

export const isSafari = (() => {
  const ua = window.navigator.userAgent;
  return /Safari/.test(ua) && !/Chrome/.test(ua);
})();

export function parseUrlSearch(key) {
  const { search } = window.location;
  const query = {};

  search
    .slice(1)
    .split('&')
    .forEach((q) => {
      const [k, v] = q.split('=');
      query[k] = v;
    });

  if (key) return query[key];
  return query;
}
// 编辑器快照
export const editorScreenshot = async () => {
  // html2canvas 有最大高度限制 http://html2canvas.hertzen.com/faq
  const MaxHeight = 1000;
  const editor = document.querySelector(
    '#knowledge_editor_box .didoc-editor-app .editor',
  );
  const title = editor.querySelector('.editor-title');
  const titleCopy = document.createElement('div');

  // html2canvas截取textarea有兼容问题，因此使用div代替
  titleCopy.setAttribute('style', title.getAttribute('style'));
  titleCopy.className = title.className;
  titleCopy.innerText = title.value;
  title.style.display = 'none';
  editor.prepend(titleCopy);

  return screenshot(editor, {
    onclone() {
      editor.removeChild(titleCopy);
      title.style.display = 'block';
    },
    ignoreElements(element) {
      const Hs = isSafari
        ? ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'IFRAME']
        : ['IFRAME'];
      const collabContainer = document.querySelector(
        '#collab-editor-container',
      );
      return (
        element.isEqualNode(collabContainer)
        || element.classList.contains('comment-line')
        || element?.dataset?.type === 'flowchart'
        || Hs.includes(element.tagName)
      );
    },
    height: Math.min(editor.scrollHeight, MaxHeight),
  });
};

// 创建一个空白blob格式数据
export const createEmptyBlob = new Blob([new ArrayBuffer(1)], {
  type: 'image/png',
});

// 搜索高亮
export const highlight = (str, color = '#0B83FF', splitKey = '§§') => {
  str = str ?? '';
  let arr = str.split(splitKey);
  if (arr.length < 3) {
    return str;
  }
  return (
    <>
      {arr.map((v, i) => (
        <Fragment key={i}>
          {i % 2 > 0 ? <span style={{ color }}>{v}</span> : v}
        </Fragment>
      ))}
    </>
  );
};

export const hitWords = (value) => {
  return value.replace(/§/g, '');
};

export const getShareLink = ({ shareId, shareType }) => {
  let commonPath = `${window.location.protocol}//${window.location.host}/knowledge`;
  if (shareType === SHARE_TYPE.DK) {
    return `${commonPath}/share/book/${shareId}`;
  }
  if (shareType === SHARE_TYPE.PAGE) {
    return `${commonPath}/share/page/${shareId}`;
  }
  return null;
};

export const getChainfromAb = async () => {
  const res = await getApolloConfig({ name: 'chain_of_outside' });
  return res;
};

export const inPhone = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isSafari = ua.indexOf('safari') !== -1 && ua.indexOf('version') !== -1;
  const isPhone = (ua.indexOf('iphone') !== -1 && ua.indexOf('version') !== -1) || ua.indexOf('android') !== -1;
  const isIPad = (isSafari && !isPhone && 'ontouchend' in document) || /ipad/i.test(ua);
  const isIPadDC = isIPad && ua.indexOf('zhushou') !== -1;
  if (isIPadDC) {
    return true;
  }

  if (isIPad) {
    return false;
  }
  if ('ontouchend' in document.body && ua.indexOf('d-chat/4') === -1) { // 注意：dc 4.0也会被识别成移动端，需要将这种情况排除
    return true;
  }
  return false;
};

export const isIpad = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isIphone = ua.indexOf('iphone') !== -1 && ua.indexOf('version') !== -1;
  const isIPad = isSafari && !isIphone && 'ontouchend' in document;
  return isIPad || /ipad/i.test(ua);
};

export const isWebkit = () => {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('applewebkit/') < 0) {
    return false;
  }
  return true;
};

export const znStrlength = (str = '') => {
  const reg = /[\u4e00-\u9fa5￥]/g;
  return str?.match(reg)?.length || 0;
};

export const beautySub = (str, len) => {
  let realen = len * 2 - znStrlength(str.substring(0, len));
  return str.substr(0, realen) + (realen < str.length ? '...' : '');
};

export const beautyAllCapitalSub = (str, len) => {
  const slice = str.substring(0, len);
  let realen = slice.length;
  return str.substr(0, realen) + (realen < str.length ? '...' : '');
};

export const isEmoji = (str) => {
  return /\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]/g.test(
    str,
  );
};
const nameCheckReg = /\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]|[%$'?*:;"/|\\]/;
export const checkErrorCharacter = (name) => {
  if (nameCheckReg.test(name)) {
    return intl.t('不支持{slot0}等特殊字符', {
      slot0: '%$\'?*:;"/|\\',
    });
  }
  return '';
};

export const checkNameErrorMsg = (v, max) => {
  let s = '';
  if (!v.trim()) {
    s = intl.t('名称不能为空');
  } else if (v.length > 200) {
    // s = intl.t('名称不能超过200个字符');
    s = intl.t('名称不能超过{count}个字符', {
      count: max || 200,
    });
  } else if (nameCheckReg.test(v) || isEmoji(v)) {
    s = intl.t('名称不能包含{slot0}等特殊字符', {
      slot0: '%$\'?*:;"/|\\',
    });
  } else {
    s = '';
  }
  return s;
};

export const chooseFileAndUpload = async (
  flag,
  addUploadConfig,
  maxFileCount,
) => {
  if (flag === 'wiki') {
    const files = await openLocalFileSelector('.zip, application/zip', false);
    [].forEach.call(files, (file) => {
      if (/\.zip$/.test(file.name)) {
        addUploadConfig({
          file,
        });
      } else {
        message.warning(intl.t('仅支持上传zip文件,非zip文件将跳过'));
      }
    });

    window.__OmegaEvent('ep_dkpc_pagemore_import_wiki_ck');
  } else if (flag === 'confluence') {
    const files = await openLocalFileSelector('.zip, application/zip', false);
    [].forEach.call(files, (file) => {
      if (/\.zip$/.test(file.name)) {
        addUploadConfig({
          file,
          type: flag,
        });
      } else {
        message.warning(intl.t('仅支持上传zip文件,非zip文件将跳过'));
      }
    });

    window.__OmegaEvent('ep_dkpc_pagemore_import_wiki_ck');
  } else if (flag === 'word') {
    const files = await openLocalFileSelector(
      '.doc,.docx,application/msword',
      false,
    );
    [].forEach.call(files, (file) => {
      if (/(\.doc|\.docx)$/.test(file.name) && file.size < WORDLIMITSIZE) {
        addUploadConfig({
          file,
          type: 'word',
        });
      } else {
        message.warning({
          key: 'uploadWikiWord',
          content: intl.t('仅支持上传20M以内的word文件,否则将跳过'),
        });
      }
    });
    window.__OmegaEvent('ep_dkpc_pagemore_import_word_ck');
  } else if (flag === 'file') {
    const files = await openLocalFileSelector(null, false);
    if (files.length > maxFileCount) {
      message.warning({
        key: 'uploadWikiWord',
        content: intl.t('最多上传{maxFileCount}个文件，请重新选择后上传', {
          maxFileCount,
        }),
      });
      return;
    }

    [].forEach.call(files, (file) => {
      if (file.size < FILELIMITSIZE) {
        addUploadConfig({
          file,
        });
      } else {
        message.warning({
          key: 'uploadWikiWord',
          content: intl.t('仅支持上传500M以内的文件,否则将跳过'),
        });
      }
    });
    window.__OmegaEvent('ep_dkpc_upload_ck');
  }
};

export const openNewWindow = (url) => {
  // 兼容新旧版safari
  if (isDesktopDC || isIpad()) {
    window.open(url);
  } else {
    let win = window.open('about:blank');
    win.location.replace(url);
  }
};

// 分享页面 || 预览页面 || 首页可以在移动端查看
export const isSupportPreview = (pathname, isbeforevision) => {
  let last = pathname;
  if (last.includes('/knowledge')) {
    if (pathname.indexOf('/m/') !== -1) {
      last = pathname.replace('/m', '');
    }
    let query = last.split('/');
    query.splice(0, 1);
    let isPageDetail = query.length === 3 && !Number.isNaN(parseFloat(query[1])) && !Number.isNaN(parseFloat(query[2]));
    let temp = pathname.split('/')
    let isEditPage = !pathname.includes('template') && temp[temp.length - 1] === 'edit'
    let isHome = temp[temp.length - 1] === 'home' && !isbeforevision;

    return (
      /(\/share)/.test(last)
      || /(\/snapshoot)/.test(last)
      || isPageDetail
      || isEditPage
      || isHome
    );
  }
  return true;
};


export const getDkRole = (perm, DK_MEMBER_TYPE) => {
  switch (perm) {
    case 1:
      return DK_MEMBER_TYPE.DK_ADMIN;
    case 2:
      return DK_MEMBER_TYPE.DK_MEMBER;
    case 3:
      return DK_MEMBER_TYPE.DK_READ_MEMBER;
    default:
      return DK_MEMBER_TYPE.DK_ADMIN;
  }
};

export const getPageRole = (perm, PAGE_MEMBER_TYPE) => {
  switch (perm) {
    case 1:
      return PAGE_MEMBER_TYPE.PAGE_MEMBER;
    case 2:
      return PAGE_MEMBER_TYPE.PAGE_READ_MEMBER;
    default:
      return PAGE_MEMBER_TYPE.PAGE_MEMBER;
  }
};

export const isPreviewFile = (docType) => {
  return docType === DocType.DK_FILE;
};

export const isDkSheet = (docType) => {
  return docType === DocType.DK_SHEET;
}

export const isDkPage = (docType) => {
  return docType === DocType.DK_PAGE;
}

export const getFileSuffix = (fileName = '') => {
  const arr = fileName.split('.');
  const res = arr.length > 1 ? arr[arr.length - 1] : intl.t('普通文件');
  return res;
};

export const fileSupportPreview = (fileName = '') => {
  const fileSuffix = getFileSuffix(fileName);
  const suffix = [
    'xlsx',
    'xls',
    'xlsm',
    'doc',
    'docx',
    'ppt',
    'pptx',
    'pdf',
    'mkv',
    'mp4',
    '3gp',
    'mov',
  ];
  return !!suffix.find((item) => item === fileSuffix);
};

export const bytesToSize = (size) => {
  let newSize;
  if (size < 0.1 * 1024) {
    // 小于0.1KB，则转化成B
    newSize = `${size.toFixed(2)}B`;
  } else if (size < 0.1 * 1024 * 1024) {
    // 小于0.1MB，则转化成KB
    newSize = `${(size / 1024).toFixed(2)}KB`;
  } else if (size < 0.1 * 1024 * 1024 * 1024) {
    // 小于0.1GB，则转化成MB
    newSize = `${(size / (1024 * 1024)).toFixed(2)}MB`;
  } else {
    // 其他转化成GB
    newSize = `${(size / (1024 * 1024 * 1024)).toFixed(2)}GB`;
  }

  let sizeStr = `${newSize}`;
  // 获取小数点处的索引
  let index = sizeStr.indexOf('.');
  // 获取小数点后两位的值
  let dou = sizeStr.substring(index + 1, 2);

  // 判断后两位是否为00，如果是则删除00
  if (dou === '00') { return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2); }

  return newSize;
};

export const getDepartmentLevel = (level = []) => {
  const len = level.length;
  if (len > 3) {
    return `${level[0]}/.../${level[len - 2]}/${level[len - 1]}`;
  }
  if (len === 3) {
    return `${level[0]}/${level[len - 2]}/${level[len - 1]}`;
  }
  return level.join('/');
};

export const getDepartmentStr = (
  str = intl.t('部门信息同步中'),
  showLength,
) => {
  if (str.length > showLength * 2) {
    return `${str.slice(0, showLength)}...${str.slice(
      str.length - showLength - 1,
    )}`;
  }
  return str;
};

export const countDown = (time, id, range) => {
  let x = setInterval(() => {
    const now = new Date().getTime();
    let end = time + range;
    const distance = end - now;
    let hours = Math.max(
      Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      0,
    );
    let minutes = Math.max(
      Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      0,
    );
    let seconds = Math.max(Math.floor((distance % (1000 * 60)) / 1000), 0);
    let dom = document.querySelector(id);
    if (dom) {
      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      dom.innerHTML = `${hours}:${minutes}:${seconds}`;
    }
    if (distance < 0) {
      clearInterval(x);
    }
  }, 1000);
};

const MHhanZhiReg = /^[^\u4e00-\u9fa5]+$/; // 包含汉字
const MHnameCheckReg = /\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]|[％%$＄`＇?？*＊:：;；＂"/／|｜\\＼]/;
const MHsymoblReg = /^[0-9a-zA-Z_-]+$/;
// 门户名称校验 门户域名校验
export const checkMHNameSymbolErrorMsg = (val, type = 'name') => {
  let flag = type === 'name'; // 判断校验类型 「 门户名称 name ｜ 门户域名 symobl 」
  let strLength = 50; // 通过类型判断字符长度
  let s = ''; // 校验提示文案

  if (!val.trim()) { s = intl.t('请填写门户{slot0}', { slot0: flag ? '名称' : '域名' }); }
  if (!s && val.length > strLength) { s = intl.t('最多支持{strLength}个字符', { strLength }); }

  // if (!s && flag && MHnameCheckReg.test(val)) s = '不支持%$\'?*:;"/|\\等特殊字符';

  if (!s && !flag && (!MHsymoblReg.test(val) || !MHhanZhiReg.test(val))) { s = intl.t('仅支持数字、英文、下划线、中划线'); }
  return s;
};

export const getUserNumber = (list) => list.filter((item) => item.memberType === 'User').length;

export const isDC = () => Boolean(window.dcJSSDK);

// 减法
export function Subtr(arg1, arg2) {
  let r1;
  let r2;
  let m;
  let n;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = 10 ** Math.max(r1, r2);
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

export const getPermis = (perm, isTeam = false) => {
  /* eslint-disable no-bitwise */
  // 此处只校验是否具有查看、编辑、下载
  let res = [];
  if ((perm & 1)) {
    res.push(intl.t('查看'));
  }
  if ((perm & 2)) {
    res.push(intl.t('编辑'));
  }
  if ((perm & 32)) {
    res.push(intl.t('下载'));
  }
  if (isTeam) {
    if ((perm & 8)) {
      res.push(intl.t('上传'));
    }
    if ((perm & 16)) {
      res.push(intl.t('分享'));
    }
  }
  /* eslint-enable no-bitwise */

  return res.length === 0 ? [intl.t('无')] : res;
};

export const highLightKeyword = (keyword, word) => {
  if (!keyword || !word) return word;

  const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // 转义正则表达式特殊字符
  const regex = new RegExp(`(${escapedKeyword})`, 'gi');
  const strLight = word.replace(regex, '**$1**');
  const strArray = strLight.split('**');

  return strArray.map((c, i) => (
    c.toLowerCase() === keyword.toLowerCase()
      ? <span
          key={i}
          style={{ color: '#0B83FF', background: 'none' }}
        >{c}</span> : c
  ));
};


export const isKnowledge = () => {
  if (window.location.href.includes('knowledge') && !window.location.href.includes('team-file')) {
    return true;
  }
  return false;
}
